import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { getallcustomers } from "../../../actions/getclients";
import { getalltransfercontractpopulate } from "../../../actions/accountsactions/transfercontract";
import { MdDeleteForever } from "react-icons/md";
import AddNominee from "./AddNominee";

export const TransferContractPage = (props) => {
  const resultallclients = useSelector((state) => state.getAllCustomersRed);
  const resultallPopulateData = useSelector(
    (state) => state.GetTransferContractPopulate
  );

  let dispatch = useDispatch();

  const [NetAmount, setNetAmount] = useState("");
  const [NetTotalAmount, setNetTotalAmount] = useState("");

  const [UnitId, setUnitId] = useState("");

  const [clientAccid, setclientAccid] = useState("");
  const [contractid, setcontractid] = useState("");

  const [loader2, setloader2] = useState(false);
  const [addinstallmentlist, setaddinstallmentlist] = useState([]);
  const [oldinstallment, setoldinstallment] = useState([]);

  var date = moment().format("YYYY-MM-DD");

  // Buyer suggestion list code start
  var Clientsuggestionlist = [];
  const clientonTextChange = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("clientdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = resultallclients.data
          .sort()
          .filter((v) => regex.test(v.CustomerName));
      }

      Clientsuggestionlist = suggestions;
      // suggestionlist.push("Add New");
      if (Clientsuggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById("clientdemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {Clientsuggestionlist.map((item) => (
              <li
                className="listcss"
                onClick={() => Clientsuggestionselected(item)}
              >
                {item === undefined ? item : item.CustomerName}
              </li>
            ))}
          </ul>,
          document.getElementById("clientdemo")
        );
      }
    }
  };

  const onTextChangeContractName = () => {
    var inputValue = document.getElementById("contractName").value;
    var options = document.getElementById("contractnamelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultallclients.data.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        setclientAccid(val.CustomerCnic);
        props.setCustomersNomineeRecord(val.CustomersNomineeRecord);
        break;
      }
    }
  };
  console.log(clientAccid);
  const Clientsuggestionselected = (val) => {
    document.getElementById("contractName").value = val.CustomerName;
    setclientAccid(val.CustomerCnic);
    props.setCustomersNomineeRecord(val.CustomersNomineeRecord);
    Clientsuggestionlist = [];
    ReactDOM.render(null, document.getElementById("clientdemo"));
  };
  // Buyer suggestion list code End

  // Buyer suggestion list code start
  var Contractsuggestionlist = [];
  const contractinfonTextChange = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("contractdetaildemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = resultallPopulateData.data.Contracts.sort().filter((v) =>
          regex.test(v.Unit.UnitName)
        );
      }

      Contractsuggestionlist = suggestions;
      // suggestionlist.push("Add New");
      if (Contractsuggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById("contractdetaildemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {Contractsuggestionlist.map((item) => (
              <li
                className="listcss"
                onClick={() => Contractsuggestionselected(item)}
              >
                {item === undefined ? item : item.Unit.UnitName}
              </li>
            ))}
          </ul>,
          document.getElementById("contractdetaildemo")
        );
      }
    }
  };

  const onTextChangeContractDetails = () => {
    var inputValue = document.getElementById("contractdetails").value;
    var options = document.getElementById("contractdetailslist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultallPopulateData.data.Contracts.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        let receivableamount = 0,
          installmentcount = 0;
        if (val.ContractInstallments.length !== 0) {
          val.ContractInstallments.forEach((x, idx) => {
            if (x.InstallmentStatus === "PENDING") {
              receivableamount += x.DueAmount - x.ReceivedAmount;
              installmentcount += 1;
            }
          });
        }

        setoldinstallmentcount(installmentcount);
        setoldreceivableamount(receivableamount);
        setoldinstallment(val.ContractInstallments);
        setcontractid(val.ContractId);
        setNetAmount(val.CustomerReceivable);
        setNetTotalAmount(val.TotalPrice);

        setUnitId(val.Unit.UnitId);
        break;
      }
    }
  };

  const Contractsuggestionselected = (val) => {
    document.getElementById("contractdetails").value = val.Unit.UnitName;

    let receivableamount = 0,
      installmentcount = 0;
    if (val.ContractInstallments.length !== 0) {
      val.ContractInstallments.forEach((x, idx) => {
        if (x.InstallmentStatus === "PENDING") {
          receivableamount += x.DueAmount - x.ReceivedAmount;
          installmentcount += 1;
        }
      });
    }

    setoldinstallmentcount(installmentcount);
    setoldreceivableamount(receivableamount);
    setoldinstallment(val.ContractInstallments);
    setcontractid(val.ContractId);
    setNetAmount(val.CustomerReceivable);
    setNetTotalAmount(val.TotalPrice);

    setUnitId(val.Unit.UnitId);

    Contractsuggestionlist = [];
    ReactDOM.render(null, document.getElementById("contractdetaildemo"));
  };

  const savecontract = () => {
    if (
      clientAccid === "" ||
      document.getElementById("contractdetails").value === ""
    ) {
      toast.info("all fields are mandatory except transfer fee");
    } else {
      if (showoldinstallment === false) {
        if (parseFloat(totalinstallmentamount) === parseFloat(NetAmount)) {
          setloader2(true);
          let installmentlist = [];

          if (showoldinstallment) {
            oldinstallment.forEach((x, idx) => {
              if (x.InstallmentStatus === "PENDING") {
                installmentlist.push({
                  DueAmount: x.DueAmount - x.ReceivedAmount,
                  DueDate: x.DueDate,
                  InstallmentNo: idx + 1,
                  ReceivedAmount: 0,
                  InstallmentStatus: "PENDING",
                });
              }
            });
          } else {
            addinstallmentlist.forEach((x, idx) => {
              installmentlist.push({
                DueAmount: x.amount,
                DueDate: x.date,
                InstallmentNo: idx + 1,
                ReceivedAmount: 0,
                InstallmentStatus: "PENDING",
              });
            });
          }

          var data = {
            EntryDate: document.getElementById("contractDate").value,
            UnitId: UnitId,
            NewCustomerCNIC: clientAccid,
            ContractId: contractid,
            TransferFee: document.getElementById("TransferFee").value,

            ContractInstallments: installmentlist,
          };
          props.setcontractdata(data);
          openmodaladdnominee();
          // props.showaddnominee();
          // axios({
          //     method: "post",
          //     url: `${process.env.REACT_APP_API_URL}Contracts/Transfer/Add`,
          //     data: data,
          //     headers: {
          //         Authorization: `Bearer ${localStorage.getItem("Token")}`,
          //         "content-type": "application/json",
          //     },
          // }).then(
          //     (result) => {
          //         console.log(result);
          //         toast.info(result.data.Message)
          //         if (result.data.IsSuccess) {
          //             setaddinstallmentlist([]);
          //             setclientAccid("")

          //             setNetAmount("")

          //             setcontractid("")

          //             setNetTotalAmount(0)
          //             setUnitId("")

          //             setoldreceivableamount(0)
          //             setoldinstallmentcount(0)
          //             setshowoldinstallment(true)
          //             document.getElementById("contractdetails").value = ""
          //             document.getElementById("contractName").value = ""

          //             document.getElementById("newcontractPrice").value = ""
          //             document.getElementById("newtotalamount").value = ""

          //             setoldinstallment([])
          //             settotalinstallmentamount(0)
          //             setdifferenceinstallmentamount(0)
          //             setloader2(false);
          //             props.formCancel();

          //         }
          //         setloader2(false);
          //     },
          //     (error) => {
          //         toast.info(error);
          //         setloader2(false);

          //     }
          // );
        } else {
          toast.info("Install amount should be equal to Total Amount!");
        }
      } else {
        setloader2(true);
        let installmentlist = [];

        if (showoldinstallment) {
          oldinstallment.forEach((x, idx) => {
            if (x.InstallmentStatus === "PENDING") {
              installmentlist.push({
                DueAmount: x.DueAmount - x.ReceivedAmount,
                DueDate: x.DueDate,
                InstallmentNo: idx + 1,
                ReceivedAmount: 0,
                InstallmentStatus: "PENDING",
              });
            }
          });
        } else {
          addinstallmentlist.forEach((x, idx) => {
            installmentlist.push({
              DueAmount: x.amount,
              DueDate: x.date,
              InstallmentNo: idx + 1,
              ReceivedAmount: 0,
              InstallmentStatus: "PENDING",
            });
          });
        }

        var data = {
          EntryDate: document.getElementById("contractDate").value,
          UnitId: parseInt(UnitId),
          NewCustomerCNIC: clientAccid,
          ContractId: contractid,
          TransferFee: document.getElementById("TransferFee").value,
          ContractInstallments: installmentlist,
        };

        props.setcontractdata(data);
        openmodaladdnominee();

        // props.showaddnominee();
        // axios({
        //     method: "post",
        //     url: `${process.env.REACT_APP_API_URL}Contracts/Transfer/Add`,
        //     data: data,
        //     headers: {
        //         Authorization: `Bearer ${localStorage.getItem("Token")}`,
        //         "content-type": "application/json",
        //     },
        // }).then(
        //     (result) => {
        //         console.log(result);
        //         toast.info(result.data.Message)
        //         if (result.data.IsSuccess) {
        //             setaddinstallmentlist([]);
        //             setclientAccid("")

        //             setNetAmount("")

        //             setcontractid("")

        //             setUnitId("")

        //             setNetTotalAmount(0)
        //             setoldinstallmentcount(0)
        //             setoldreceivableamount(0)
        //             setoldinstallment([])
        //             setshowoldinstallment(true)

        //             document.getElementById("contractdetails").value = ""
        //             document.getElementById("contractName").value = ""

        //             document.getElementById("newcontractPrice").value = ""
        //             document.getElementById("newtotalamount").value = ""

        //             settotalinstallmentamount(0)
        //             setdifferenceinstallmentamount(0)
        //             setloader2(false);
        //             props.formCancel();

        //         }
        //         setloader2(false)

        //     },
        //     (error) => {
        //         toast.info(error);
        //         setloader2(false);

        //     }
        // );
      }
    }
  };

  const [totalinstallmentamount, settotalinstallmentamount] = useState(0);
  const [differenceinstallmentamount, setdifferenceinstallmentamount] =
    useState(0);
  useEffect(() => {
    let sum = 0;
    addinstallmentlist.forEach((x) => {
      sum += parseFloat(x.amount);
    });

    settotalinstallmentamount(sum);
  }, [addinstallmentlist]);

  useEffect(() => {
    if (NetAmount !== "") {
      setdifferenceinstallmentamount(NetAmount - totalinstallmentamount);
    }
  }, [NetAmount, totalinstallmentamount]);

  const addinstallment = () => {
    if (
      document.getElementById("customAmount").value !== "" &&
      document.getElementById("customDate").value !== ""
    ) {
      var obj = {
        amount: document.getElementById("customAmount").value,
        date: document.getElementById("customDate").value,
      };
      setaddinstallmentlist([...addinstallmentlist, obj]);
      document.getElementById("customAmount").value = "";
    } else {
      toast.info("Please enter amount!");
    }
  };

  const deleteinstallment = (idx) => {
    const rows = [...addinstallmentlist];
    rows.splice(idx, 1);

    setaddinstallmentlist(rows);
  };

  const [showoldinstallment, setshowoldinstallment] = useState(true);
  const [oldreceivableamount, setoldreceivableamount] = useState(0);
  const [oldinstallmentcount, setoldinstallmentcount] = useState(0);
  const radiobuttonchange = (e) => {
    if (e.target.value === "keepsame") {
      document.getElementById("hidediv1").style.display = "none";
      document.getElementById("hidediv2").style.display = "none";
      document.getElementById("hidediv3").style.display = "none";
      document.getElementById("hidediv4").style.display = "none";
      setshowoldinstallment(true);
    } else {
      document.getElementById("hidediv1").style.display = "block";
      document.getElementById("hidediv2").style.display = "block";
      document.getElementById("hidediv3").style.display = "block";
      document.getElementById("hidediv4").style.display = "block";
      setshowoldinstallment(false);
    }
  };

  useEffect(() => {
    dispatch(getallcustomers());

    dispatch(getalltransfercontractpopulate());
  }, [dispatch]);
  const openmodaladdnominee = (x) => {
    document.getElementById("modalofaddnominee").style.display = "block";
  };
  return (
    <>
      <div className="row ">
        <div className="col-md-12  text-left page_heading">
          <h4 className="m-0">Transfer Contract</h4>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-3"></div>
        <div className="col-2">
          <span className="txt">Unit Name</span>
        </div>
        <div className="col-4  text-left">
          <span>
            {" "}
            <input
              type="text"
              id="contractdetails"
              autoComplete="off"
              onChange={onTextChangeContractDetails}
              className="input_styling"
              list="contractdetailslist"
            ></input>
            <datalist id="contractdetailslist">
              {resultallPopulateData?.data?.Contracts?.map((x, idx) => (
                <option index={idx} value={x.Unit?.UnitName}>
                  {x.Unit?.UnitId}
                </option>
              ))}
            </datalist>
          </span>

          <span id="contractdetaildemo"></span>
        </div>
        <div className="col-3"></div>
      </div>
      <div className="row pt-3">
        <div className="col-2 ">
          {" "}
          <span className="txt">Buyer Name</span>
        </div>
        <div className="col-4  text-left">
          {" "}
          <span>
            {" "}
            <input
              type="text"
              id="contractName"
              autoComplete="off"
              onChange={onTextChangeContractName}
              className="input_styling"
              list="contractnamelist"
            ></input>
            <datalist id="contractnamelist">
              {resultallclients?.data?.map((x, idx) => (
                <option index={idx} value={x.CustomerName}>
                  {x.CustomerCnic2}
                </option>
              ))}
            </datalist>
          </span>
          <span id="clientdemo"></span>
        </div>
        <div className="col-2  ">
          <span className="txt">Date</span>
        </div>
        <div className="col-4  text-left">
          <span>
            {" "}
            <input
              type="date"
              id="contractDate"
              className="input_styling"
              defaultValue={date}
            ></input>
          </span>
        </div>
        <div className="col-2 mt-3 ">
          <span className="txt">Transfer Fee.</span>
        </div>
        <div className="col-4 mt-3 text-left">
          <span>
            {" "}
            <input
              type="text"
              id="TransferFee"
              autoComplete="off"
              className="input_styling"
              onChange={(e) => {
                if (e.target.value.length > 0)
                  document.getElementById("TransferFee").value = parseInt(
                    e.target.value.replace(/,/g, "")
                  ).toLocaleString();
              }}
            />
          </span>
        </div>

        <div className="col-2 mt-3 ">
          <span className="txt">Receivable Amount</span>
        </div>
        <div className="col-4 mt-3 text-left">
          <span>
            {" "}
            <input
              type="text"
              disabled
              value={
                showoldinstallment
                  ? oldreceivableamount.toLocaleString()
                  : NetAmount.toLocaleString()
              }
              id="newcontractPrice"
              className="input_styling"
            ></input>
          </span>
        </div>

        <div className="col-2 mt-3 ">
          <span className="txt">Total Amount</span>
        </div>
        <div className="col-4 mt-3 text-left">
          <span>
            {" "}
            <input
              type="text"
              disabled
              value={NetTotalAmount.toLocaleString()}
              id="newtotalamount"
              className="input_styling"
            ></input>
          </span>
        </div>

        <div className="col-2 mt-3 ">
          <span className="txt"></span>
        </div>
        <div className="col-4 mt-3 text-left">
          <span>
            {" "}
            <input
              onClick={(e) => radiobuttonchange(e)}
              type="radio"
              name="gender"
              value="reschedule"
            />{" "}
            Reschedule
            <br />
            <input
              onClick={(e) => radiobuttonchange(e)}
              type="radio"
              name="gender"
              value="keepsame"
              defaultChecked="checked"
            />{" "}
            Keep Same
          </span>
        </div>

        <div className="col-2 mt-3" id="hidediv1" style={{ display: "none" }}>
          <span className="txt">Installments</span>
        </div>
        <div
          className="col-4 mt-3 text-left"
          id="hidediv2"
          style={{ display: "none" }}
        >
          <span>
            {" "}
            <input
              type="number"
              placeholder="Amount"
              id="customAmount"
              className="input_styling pl-0 pr-0  col-5"
            />
          </span>
          <span>
            <input
              type="date"
              id="customDate"
              className="input_styling  pr-0 pl-0 col-5 ml-1"
            />
          </span>
          <span>
            {" "}
            {/* <img
                                    src={plus}
                                    className="ml-1 removerow"
                                    onClick={addinstallment}
                                /> */}
            <button onClick={addinstallment} className="save_btn">
              Add
            </button>
          </span>
        </div>

        <div className="col-2 mt-3" id="hidediv3" style={{ display: "none" }}>
          <span className="txt">No of Installment</span>
        </div>
        <div
          className="col-4 mt-3 text-left"
          id="hidediv4"
          style={{ display: "none" }}
        >
          <span>
            {" "}
            <input
              type="text"
              id="NoOfInstallements"
              value={addinstallmentlist.length}
              readOnly
              placeholder="Add Installement"
              className="input_styling"
            />
          </span>
        </div>

        <div className="col-2  text-right"></div>
        <div className="col-4  text-left"></div>

        <div className="col-2  text-right"></div>
        <div className="col-4  text-left">
          <br></br>
          <span className="ml-1 float-right">
            <button
              id="repursave"
              onClick={savecontract}
              className="save_btn  text-right  float-right mt-1"
            >
              Next
            </button>
          </span>
          <span className="ml-1 float-right">
            <button
              onClick={props.formCancel}
              className="cancel_btn  float-right mt-1 mr-2 text-right "
            >
              Cancel
            </button>
          </span>
          <span className=" ml-1 float-right">
            {/* <div className="loaderclass ml-1">
                            {" "}
                            {loader2 && (
                                <Loader
                                    type="ThreeDots"
                                    color="green"
                                    height={40}
                                    width={40}
                                />
                            )}
                        </div> */}
          </span>
        </div>
      </div>
      <div className="  mt-3 overflow-auto" style={{ height: "275px" }}>
        <table class="table tableCash table-hover">
          <thead class="purple whiteText">
            <tr>
              <th scope="col">Installment No</th>
              <th scope="col">Due Amount</th>
              {/* <th scope="col">Amount</th> */}
              <th scope="col">Date</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {showoldinstallment
              ? oldinstallment.map((x, idx) => (
                  <tr>
                    <th scope="row">{idx + 1}</th>
                    {/* <td>{x.Pendingamount.toLocaleString()}</td> */}
                    <td>
                      {parseFloat(
                        x.DueAmount - x.ReceivedAmount
                      ).toLocaleString()}
                    </td>
                    <td>
                      {moment(x.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                    </td>
                    <td>{x.InstallmentStatus}</td>
                  </tr>
                ))
              : addinstallmentlist.map((x, idx) => (
                  <tr>
                    <th scope="row">{idx + 1}</th>

                    <td>{x.amount.toLocaleString()}</td>
                    <td>{x.date}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        deleteinstallment(idx);
                      }}
                    >
                      <h6>
                        <MdDeleteForever size="1.6em" />
                      </h6>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <div className="row pt-3">
        <div className="col-6 text-center">
          <b>Total:</b>{" "}
          {showoldinstallment
            ? oldreceivableamount.toLocaleString()
            : totalinstallmentamount}
        </div>
        <div className="col-6 text-center">
          <b>Difference:</b>{" "}
          {showoldinstallment ? 0 : differenceinstallmentamount}
        </div>
      </div>

      <div id="modalofaddnominee" class="modalscreen ">
        <div class="modal-content-dashboard pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center "> Add Nominee</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofaddnominee").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12  mt-3">
              <AddNominee
                formCancel={props.formCancel}
                statecontractdata={props.statecontractdata}
                stateCustomersNomineeRecord={props.stateCustomersNomineeRecord}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
